import PAGE_TYPES from 'tradera/constants/page-types';
import { OPTIONAL_LANG } from 'tradera/apps/tradera/helpers/optional-lang';

export const traderaRoutes = {
    traderaShopPage: {
        path: `${OPTIONAL_LANG}/tradera-shop/:urlSlug`,
        pageType: PAGE_TYPES.TRADERA_SHOP_PAGE
    },
    upiPage: {
        path: `${OPTIONAL_LANG}/resolution/UPI2/:purchaseOrderId`,
        pageType: PAGE_TYPES.DEFAULT
    },
    discontiunedSalePage: {
        path: `${OPTIONAL_LANG}/resolution/DCS/:purchaseOrderId`,
        exact: true,
        pageType: PAGE_TYPES.DEFAULT
    },
    discontiunedSaleConfirmationPage: {
        path: `${OPTIONAL_LANG}/resolution/MCP/:purchaseOrderId/confirm`,
        pageType: PAGE_TYPES.DEFAULT
    },
    inrPage: {
        path: `${OPTIONAL_LANG}/resolution/INR2/:purchaseOrderId`,
        pageType: PAGE_TYPES.DEFAULT
    },
    checkoutConfirmationPage: {
        path: `${OPTIONAL_LANG}/shopping/ordercheckout/confirmation/:purchaseOrderId`,
        pageType: PAGE_TYPES.DEFAULT
    },
    checkoutPage: {
        path: `${OPTIONAL_LANG}/shopping/ordercheckout/:purchaseOrderId`,
        pageType: PAGE_TYPES.DEFAULT
    },
    cartPage: {
        path: `${OPTIONAL_LANG}/shopping/cart`,
        pageType: PAGE_TYPES.DEFAULT
    },
    favouriteSellersPage: {
        path: `${OPTIONAL_LANG}/favourite-sellers`,
        pageType: PAGE_TYPES.FAVOURITE_SELLERS_PAGE
    },
    recommendationsPage: {
        path: `${OPTIONAL_LANG}/ma/endless-recommendation/:recommendationModeId(selected|endingSoon)`,
        pageType: PAGE_TYPES.ENDLESS_RECOMMENDATIONS_PAGE
    },
    reportItemPage: {
        path: `${OPTIONAL_LANG}/report-item/:itemId`,
        pageType: PAGE_TYPES.REPORT_ITEM_PAGE
    },
    CombinedShippingInfoPage: {
        path: `${OPTIONAL_LANG}/frakt/samfrakt`,
        pageType: PAGE_TYPES.DEFAULT
    },
    ShippingInfoPage: {
        path: `${OPTIONAL_LANG}/frakt`,
        pageType: PAGE_TYPES.DEFAULT
    },
    ValuationPage: {
        path: `${OPTIONAL_LANG}/valuation`,
        pageType: PAGE_TYPES.VALUATION
    },
    PrintPage: {
        path: `${OPTIONAL_LANG}/print`,
        pageType: PAGE_TYPES.DEFAULT
    },
    KycPage: {
        path: `${OPTIONAL_LANG}/verify/kyc-questionnaire`,
        pageType: PAGE_TYPES.DEFAULT
    },
    CollectVatNumber: {
        path: `${OPTIONAL_LANG}/verify/collect-vat-number`,
        pageType: PAGE_TYPES.DEFAULT
    },
    CollectTin: {
        path: `${OPTIONAL_LANG}/verify/collect-tin`,
        pageType: PAGE_TYPES.DEFAULT
    },
    messaging: {
        path: [
            `${OPTIONAL_LANG}/messaging/thread/:threadId`,
            `${OPTIONAL_LANG}/messaging/threads/:threadId`,
            `${OPTIONAL_LANG}/messaging`
        ],
        pageType: PAGE_TYPES.MY_MESSAGES_PAGE
    },
    notFoundPage: {
        path: '*',
        pageType: PAGE_TYPES.ERROR_PAGE,
        exact: true
    }
};
