import { createBrowserHistory } from 'history';
import { isServer } from 'tradera/utils/nextjs';
import type { History } from 'history';

const history = isServer ? null : createBrowserHistory();

export const getHistory = () => {
    if (isServer) {
        throw new Error('Do not use history on server');
    }
    return history as History;
};
