import mapObject from 'lodash/mapValues';
import { REDIRECT_TO_NEWDRAFT_QUERY } from 'tradera/apps/syi/constants';
import { OPTIONAL_LANG } from 'tradera/apps/tradera/helpers/optional-lang';
import PAGE_TYPES from 'tradera/constants/page-types';

export const myTraderaUrls = {
    overview: '/my/overview',
    overview2: '/my/overview2',
    buyerOverview: '/my/overview-buyer',
    sellerOverview: '/my/overview-seller',
    accountOverview: '/my/overview-my-account',
    wishlist: '/my/wish-list',
    profile: '/profile/items/:memberId/:cleanedMemberAlias',
    activeItems: '/my/active-items',
    itemsLost: '/my/items-lost',
    active: '/my/active',
    sold: '/my/sold',
    unsold: '/my/unsold',
    listings: '/my/listings',
    listingsUnsold: '/my/listings?tab=unsold',
    bidding: '/my/bidding',
    purchases: '/my/purchases',
    savedSearches: '/my/saved-searches',
    sellerList: '/my/seller-list',
    pickList: '/my/picklist',
    leaveFeedback: '/my/leave-feedback/:role',
    buyerFeedback: '/my/leave-feedback/buyer',
    sellerFeedback: '/my/leave-feedback/seller',
    myFeedback: '/my/feedback',
    invoices: '/my/invoices',
    templates: '/selling/templates',
    payouts: '/my/payouts',
    accounting: '/my/accounting',
    messaging: '/messaging',
    selling: `/selling?${REDIRECT_TO_NEWDRAFT_QUERY}`,
    sellingTemplates: '/selling/templates',
    settings: '/my/settings',
    settingsAccount: '/my/settings/account',
    settingsEmail: '/my/settings/email',
    settingsSelling: '/my/settings/selling',
    settingsNotifications: '/my/settings/notifications',
    settingsSecurity: '/my/settings/security',
    settingsPayouts: '/my/settings/payouts',
    settingsLocalization: '/my/settings/localization',
    buyShippingBags: '/tradera-shop/shipping-bags',
    payeeVerification: '/my/PayeeVerification',
    hyperwalletPayeeVerificationCompleted: '/my/PayeeVerificationCompleted',
    shippingCheckout: '/my/shipping/checkout',
    shippingBook: '/my/shipping',
    shippingConfirmation: '/my/shipping/confirmation/:shipmentCheckoutId',
    invoiceCheckout: '/my/invoices/payment/:invoiceId',
    invoiceConfirmation: '/my/invoices/confirmation/:invoiceId',
    traderaShop: '/tradera-shop',
    notFound: '/my/settings/*'
} as const;

export const getMyTraderaUrls = (
    toLocalizedUrl: (url: string) => string
): Record<keyof typeof myTraderaUrls, string> =>
    mapObject(myTraderaUrls, (url) => toLocalizedUrl(url));

export const myTraderaRouteUrls = mapObject(
    myTraderaUrls,
    (url) => `${OPTIONAL_LANG}${url}`
);

export const myTraderaRoutes = mapObject(
    {
        SellerListPage: {
            path: myTraderaRouteUrls.sellerList
        },
        SavedSearchesPage: {
            path: myTraderaRouteUrls.savedSearches
        },
        PayeeVerificationPage: {
            path: myTraderaRouteUrls.payeeVerification
        },
        HyperwalletPayeeVerificationCompletedPage: {
            path: myTraderaRouteUrls.hyperwalletPayeeVerificationCompleted
        },
        BuyerActiveItemsPage: {
            path: myTraderaRouteUrls.activeItems
        },
        PurchasesPage: {
            path: myTraderaRouteUrls.purchases
        },
        ItemsLostPage: {
            path: myTraderaRouteUrls.itemsLost
        },
        ListingsPage: {
            path: myTraderaRouteUrls.listings
        },
        ListingsUnsoldPage: {
            path: myTraderaRouteUrls.listingsUnsold
        },
        ActivePage: {
            path: myTraderaRouteUrls.active
        },
        UnsoldPage: {
            path: myTraderaRouteUrls.unsold
        },
        PayoutsPage: {
            path: myTraderaRouteUrls.payouts
        },
        AccountingPage: {
            path: myTraderaRouteUrls.accounting
        },
        SettingsPage: {
            path: myTraderaRouteUrls.settings,
            exact: true
        },
        SettingsAccountPage: {
            path: myTraderaRouteUrls.settingsAccount
        },
        SettingsEmailPage: {
            path: myTraderaRouteUrls.settingsEmail
        },
        SettingsNotificationsPage: {
            path: myTraderaRouteUrls.settingsNotifications
        },
        SettingsSellingPage: {
            path: myTraderaRouteUrls.settingsSelling
        },
        SettingsSecurityPage: {
            path: myTraderaRouteUrls.settingsSecurity
        },
        SettingsPayoutsPage: {
            path: myTraderaRouteUrls.settingsPayouts
        },
        SettingsLocalizationPage: {
            path: myTraderaRouteUrls.settingsLocalization
        },
        LeaveFeedbackPage: {
            path: myTraderaRouteUrls.leaveFeedback
        },
        ShippingCheckoutPage: {
            path: myTraderaRouteUrls.shippingCheckout
        },
        ShippingConfirmationPage: {
            path: myTraderaRouteUrls.shippingConfirmation
        },
        NotFoundPage: {
            path: myTraderaRouteUrls.notFound
        },
        InvoicesPage: {
            path: myTraderaRouteUrls.invoices
        },
        InvoiceCheckoutPage: {
            path: myTraderaRouteUrls.invoiceCheckout
        },
        InvoiceConfirmationPage: {
            path: myTraderaRouteUrls.invoiceConfirmation
        },
        ShippingBookPage: {
            path: myTraderaRouteUrls.shippingBook
        },
        PickList: {
            path: myTraderaRouteUrls.pickList
        }
    },
    (route) => ({
        pageType: PAGE_TYPES.MY_TRADERA_PAGE,
        ...route
    })
);
