import { traderaRoutes } from 'tradera/apps/tradera/routes';
import { syiRoutes } from 'tradera/apps/syi/routes';
import { myTraderaRoutes } from 'tradera/apps/my-tradera/app/pages/routes';
import { razorRoutes } from './razor-routes';

const { catchAll, ...syiRoutesWithoutCatchAll } = syiRoutes;
const { notFoundPage, ...traderaRoutesWithoutNotFound } = traderaRoutes;

export const TOUCHWEB_ROUTES = [
    ...Object.values(syiRoutesWithoutCatchAll),
    ...Object.values(myTraderaRoutes),
    ...Object.values(traderaRoutesWithoutNotFound)
];

export const ALL_ROUTES = [
    ...TOUCHWEB_ROUTES,
    ...Object.values(razorRoutes),
    notFoundPage
];
