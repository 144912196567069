import { isServer } from 'tradera/utils/nextjs';
import { isDev } from 'tradera/utils/environment';

const ENVIRONMENT_DEVELOPMENT = 'development';
const SAMPLE_RATE = 0.2;
export const TRACES_SAMPLE_RATE = SAMPLE_RATE * 0.007;
const HIGH_TRAFFIC_TRACES_SAMPLE_RATE = TRACES_SAMPLE_RATE * 0.1;
export const REPLAY_SAMPLE_RATE = 0.05; // In practice sub-set of SAMPLE_RATE * REPLAY_SAMPLE_RATE, so 0,01
export const PROFILING_SAMPLE_RATE = 0.5; // relative to TRACES_SAMPLE_RATE
const PRODUCTION_URL_REGEX = /(www|static|canary)\.tradera\.(com|se|net)/;
const STAGING_URL_REGEX = /(beta-(a|b|c|verify)\.)tradera\.com/;
const DEVELOPMENT_URL_REGEXES = [
    /webpack-internal:\/\//,
    /localhost:\d+/,
    /127\.0\.0\.1:\d+/,
    /10\.\d+\.\d+\.\d+:\d+/,
    /192\.168.\.\d+\.\d+:\d+/,
    /((\w|-)+\.)+tradera\.(dev|service)/
];
const HIGH_TRAFFIC_ROUTES = [
    '/',
    '/search',
    '/category/[category]',
    '/item/[id]',
    '/item/[id]/[itemId]/[slug]',
    'GET /api/version',
    'GET /api/i18n/translations/[lng]/[ns]'
];

const isHighTrafficRoute = (transactionName) =>
    HIGH_TRAFFIC_ROUTES.includes(transactionName);

const getEventRequestUrl = (event) => {
    try {
        return new URL(event.request.url);
    } catch (error) {
        return {};
    }
};

const getClientUrlPart = (urlPart) => {
    if (isServer) {
        return null;
    }

    try {
        return new URL(window?.location)?.[urlPart];
    } catch (error) {
        return null;
    }
};

const isDebuggingEnabled = (searchParams) =>
    Boolean(searchParams?.has?.('debug-sentry'));

export const isDebuggingEnabledClientSideOnly = () =>
    !isServer && isDebuggingEnabled(getClientUrlPart('searchParams'));

export const shouldLoadReplay = () =>
    isDebuggingEnabledClientSideOnly() ||
    (!isDev() && Math.random() < REPLAY_SAMPLE_RATE);

export const SHARED_FRONTEND_CONFIG = {
    maxBreadcrumbs: 25,
    normalizeDepth: 5,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0, // Sampled in integrations config
    allowUrls: [
        PRODUCTION_URL_REGEX,
        STAGING_URL_REGEX,
        ...DEVELOPMENT_URL_REGEXES
    ],
    denyUrls: [
        /chrome:\/\//i,
        /extensions\//i,
        /moz-extension:\/\//i,
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        /pixelsafe\.adsafeprotected.com/,
        /gstatic/,
        /gpt/i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net/i,
        /cdn\.ampproject\.org/i,
        /adform\.net/i
    ],
    ignoreErrors: [
        'Load failed',
        'Loading chunk',
        'Loading CSS chunk',
        'TypeError: Failed to fetch',

        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        'ResizeObserver loop limit exceeded',

        // https://tradera-sprints.atlassian.net/browse/TWT-7151.
        // We tried to fix this with no success. It does not affect the user experience and seem to only affect a handful of our users.
        'ResizeObserver loop completed with undelivered notifications.',

        // Google Translate extension: https://github.com/getsentry/sentry-javascript/issues/2418
        `TypeError: a[b].target.className.indexOf is not a function. (In 'a[b].target.className.indexOf(Zb)', 'a[b].target.className.indexOf' is undefined)`,
        'UET is not defined',
        'should_do_lastpass_here',
        // Error generated by a bug in SamsungBrowser/7.2
        'document.getElementsByClassName.ToString is not a function',
        `ReferenceError: Can't find variable: MoatMAK`
    ]
};

export const getEnvironmentFromOrigin = (origin) => {
    if (PRODUCTION_URL_REGEX.test(origin)) {
        return 'production';
    }
    if (STAGING_URL_REGEX.test(origin)) {
        return 'staging';
    }
    if (DEVELOPMENT_URL_REGEXES.some((regex) => regex.test(origin))) {
        return ENVIRONMENT_DEVELOPMENT;
    }
    if (process?.env?.SENTRY_ENVIRONMENT) {
        return process.env.SENTRY_ENVIRONMENT;
    }
    if (process?.env?.NODE_ENV) {
        return process.env.NODE_ENV;
    }
    return 'unknown';
};

export const SENTRY_REPLAY_CONFIG = {
    maskAllInputs: true,
    maskAllText: false,
    blockAllMedia: false
};

export const beforeSend = ({ event, hint, isServer }) => {
    const requestUrl = getEventRequestUrl(event);
    const environment = getEnvironmentFromOrigin(requestUrl?.origin);
    if (isServer) {
        console.error(hint.originalException || hint.syntheticException);
    } else {
        console.error(
            hint.originalException || hint.syntheticException,
            event // Logs all data that would have been sent to Sentry (such as context & tags)
        );
    }
    const debugSentry = isDebuggingEnabled(requestUrl?.searchParams);
    if (debugSentry) {
        return event;
    }

    if (environment === ENVIRONMENT_DEVELOPMENT) {
        return null;
    }

    const shouldSampleEvent = Math.random() < SAMPLE_RATE;
    if (!shouldSampleEvent) {
        return null;
    }

    return event;
};

export const tracesSampler = ({
    urlSearchParams,
    origin,
    parentSampled,
    transactionName
}) => {
    if (parentSampled !== undefined) {
        // Inherit parent transaction sampling for complete traces
        return parentSampled;
    }

    const searchParams = new URLSearchParams(urlSearchParams);
    const isEnabledByEnvironment = Boolean(process.env.DEBUG_SENTRY);
    const isEnabledByByUrl = isDebuggingEnabled(searchParams);
    if (isEnabledByEnvironment || isEnabledByByUrl) {
        return 1;
    }

    const environment = getEnvironmentFromOrigin(origin);
    if (environment === ENVIRONMENT_DEVELOPMENT) {
        return 0;
    }

    if (isHighTrafficRoute(transactionName)) {
        return HIGH_TRAFFIC_TRACES_SAMPLE_RATE;
    }

    return TRACES_SAMPLE_RATE;
};
