/* eslint-disable better-mutation/no-mutation */

import * as Sentry from '@sentry/react';
import { dedupeIntegration } from '@sentry/browser';
import { matchPath } from 'react-router-dom';
import initData from 'init-data';
import { getHistory } from 'tradera/utils/history';
import { axiosIntegration } from 'tradera/utils/sentry/axios-integration';
import { ALL_ROUTES } from 'tradera/constants/routes';
import {
    SHARED_FRONTEND_CONFIG,
    SENTRY_REPLAY_CONFIG,
    TRACES_SAMPLE_RATE,
    shouldLoadReplay,
    beforeSend,
    getEnvironmentFromOrigin
} from 'tradera/utils/sentry/config';
import { isServer } from 'tradera/utils/nextjs';

if (isServer) {
    // Do not remove this without also removing the disable of eslint better-mutation/no-mutation at top of file.
    throw new Error(
        'This implementation may not compatible with SSR where global properties are shared between server instances'
    );
}

window.Sentry = Sentry;

const DEBUG_SENTRY = window.location.search.includes('debug-sentry');

try {
    // https://docs.sentry.io/error-reporting/configuration/?platform=javascript
    Sentry.init({
        dsn: 'https://aded37c82da24c40b403e8790998c27d@sentry.io/143263',
        environment: getEnvironmentFromOrigin(window.location.origin),
        debug: DEBUG_SENTRY,
        release: window.releaseVersion,
        // Sample rate is controlled by selective loading through the feature toggle "sentry"
        sampleRate: 1.0,
        tracesSampleRate: DEBUG_SENTRY ? 1 : TRACES_SAMPLE_RATE,

        integrations: (integrations) => {
            integrations.push(
                Sentry.reactRouterV5BrowserTracingIntegration({
                    enableInp: true,
                    history: getHistory(),
                    routes: ALL_ROUTES,
                    matchPath
                }),
                dedupeIntegration(),
                axiosIntegration()
            );

            if (shouldLoadReplay()) {
                integrations.push(
                    Sentry.replayIntegration(SENTRY_REPLAY_CONFIG)
                );
            }
            return integrations;
        },
        beforeSend: (event, hint) =>
            beforeSend({ event, hint, isServer: false }),
        beforeBreadcrumb(breadcrumb, hint) {
            if (breadcrumb.category === 'ui.click') {
                const { target } = hint.event;
                if (target && target.ariaLabel) {
                    breadcrumb.message = target.ariaLabel;
                }
            }
            return breadcrumb;
        },
        ...SHARED_FRONTEND_CONFIG
    });

    if (initData) {
        if (initData.isLoggedIn) {
            Sentry.setUser({
                id: initData.memberId,
                username: initData.memberAlias
            });
        }
        Sentry.setTag('loggedIn', initData.isLoggedIn);
        Sentry.setTag('isHybridAppContext', initData.isHybridAppContext);
        Sentry.setTag('isNativeAppContext', initData.isNativeAppContext);
    }
} catch (e) {
    console.error(e);
}
