import axios from 'axios';
import type { AxiosRequestConfig } from 'axios';
import type {
    IntegrationFn,
    IntegrationFnResult,
    Contexts,
    Event,
    EventHint
} from '@sentry/types';

const excludeConfigKeys = ['transformRequest', 'transformResponse'];

const INTEGRATION_NAME = 'Axios';

const filterConfig = (key: string, value: unknown): boolean => {
    if (typeof value === 'function') return false;
    if (excludeConfigKeys.includes(key)) return false;
    return true;
};

export const axiosIntegration: IntegrationFn = (): IntegrationFnResult => {
    return {
        name: INTEGRATION_NAME,
        // TODO: Remove setupOnce after upgrading to v8
        setupOnce() {},
        processEvent(
            event: Event,
            hint: EventHint
        ): Event | PromiseLike<Event | null> | null {
            const contexts: Contexts = {
                ...event.contexts
            };
            const error = hint.originalException;

            if (axios.isAxiosError(error)) {
                const axiosConfig = error.config;
                const serializableConfig: AxiosRequestConfig = {};
                for (const key in axiosConfig) {
                    const value = axiosConfig[key as keyof AxiosRequestConfig];
                    if (filterConfig(key, value)) {
                        serializableConfig[key as keyof AxiosRequestConfig] =
                            value;
                    }
                }
                contexts.axios = {
                    config: serializableConfig,
                    response: {
                        status: error.response?.status,
                        statusText: error.response?.statusText
                    }
                };
            }

            return {
                ...event,
                contexts
            };
        }
    };
};
