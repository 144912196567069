import { OPTIONAL_LANG } from 'tradera/apps/tradera/helpers/optional-lang';

export const razorRoutes = [
    '/search/detailed',
    '/search/detailed/member',
    '/Shopping/Checkout/SelectPayment',
    '/my/overview-my-account',
    '/my/overview-buyer',
    '/my/overview',
    '/my/notifications',
    '/my/picklist/print',
    '/my/profile/changed-password',
    '/my/profile/changepassword',
    '/my/profile/changed-password',
    '/my/profile/enter-cellphonenumber',
    '/my/sold/buyer-info/print',
    '/register/swedish-company',
    '/register/foreign-company',
    '/register/foreign-private',
    '/register',
    '/login/connected',
    '/login/changepassword/success',
    '/login/changepassword',
    '/login/accountlocked',
    '/login/accountlockedanonymous',
    '/login/entercellnumber',
    '/login/enterusername',
    '/login/enteruserdetails',
    '/login/forgotpassword',
    '/login/forgotusercredentials',
    '/login/multifactorauthentication/listverificationoptions',
    '/login/multifactorauthentication/enterverificationcode',
    '/login/resetemail',
    '/login/verifybankId',
    '/login/verifycellnumber',
    '/login/verifysmscode',
    '/login',
    '/resolution/UPI/:itemId',
    '/resolution/disputes/:itemId/completed',
    '/resolution/disputes/:itemId/respond',
    '/resolution/disputes/:itemId/ResponseSubmitted',
    '/resolution/disputes/:itemId',
    '/resolution/FeedbackRemovalRequest/:itemId/Submission',
    '/resolution/FeedbackRemovalRequest/:itemId',
    '/resolution/FeedbackRemovalConfirmation/:feedbackRemovalRequestId/ConfirmationDetails',
    '/resolution/FeedbackRemovalConfirmation/:feedbackRemovalRequestId'
].map((path) => ({
    path: `${OPTIONAL_LANG}${path}`,
    exact: true
}));
