import mapObject from 'lodash/mapValues';
import { OPTIONAL_LANG } from 'tradera/apps/tradera/helpers/optional-lang';
import PAGE_TYPES from 'tradera/constants/page-types';

export const syiUrls = {
    START: '/selling',
    CAMPAIGN: '/selling/campaign/:campaignCode',
    DRAFTS: '/selling',
    DRAFT: '/selling/draft/:draftId',
    DRAFT_SUMMARY: '/selling/summary/:draftId',
    EDIT_DRAFT_PROPERTIES: '/selling/draft/:draftId/edit/:propertyNames',
    NEW_DRAFT: '/selling/new',
    NEW_DRAFT_PARAMETERS: '/selling/draft/from-parameters',
    TEMPLATES: '/selling/templates',
    TEMPLATE: '/selling/template/:templateId',
    EDIT_TEMPLATE_PROPERTIES:
        '/selling/template/:templateId/edit/:propertyNames',
    PUBLISHED: '/selling/published/:itemId',
    EXTRA: '/selling/extra/:draftId',
    FEATURES: '/selling/features/:itemId'
};

export const getSyiUrls = (toLocalizedUrl) =>
    mapObject(syiUrls, (url) => toLocalizedUrl(url));

export const syiRouteUrls = mapObject(
    syiUrls,
    (url) => `${OPTIONAL_LANG}${url}`
);

export const syiRoutes = mapObject(
    {
        draftsPage: {
            path: [syiRouteUrls.START, syiRouteUrls.DRAFTS],
            exact: true
        },
        draftPage: {
            path: [
                syiRouteUrls.DRAFT,
                syiRouteUrls.NEW_DRAFT,
                syiRouteUrls.CAMPAIGN
            ],
            exact: true
        },
        draftSummaryPage: {
            path: syiRouteUrls.DRAFT_SUMMARY
        },
        templatesPage: {
            path: syiRouteUrls.TEMPLATES,
            exact: true
        },
        templatePage: {
            path: syiRouteUrls.TEMPLATE,
            exact: true
        },
        editTemplatePropertyPage: {
            path: syiRouteUrls.EDIT_TEMPLATE_PROPERTIES,
            exact: true
        },
        editDraftPropertyPage: {
            path: syiRouteUrls.EDIT_DRAFT_PROPERTIES,
            exact: true
        },
        publishedPage: {
            path: syiRouteUrls.PUBLISHED,
            strict: true
        },
        changeWhenBidsPage: {
            path: syiRouteUrls.EXTRA,
            strict: true
        },
        addonsPage: {
            path: syiRouteUrls.FEATURES
        },
        catchAll: {
            path: '*'
        }
    },
    (route) => ({
        pageType: PAGE_TYPES.SYI_PAGE,
        ...route
    })
);
