export default {
    ACCOUNT_LOCKED_PAGE: 'AccountLocked',
    BRAND_PAGE: 'Brand',
    BRAND_LIST_PAGE: 'BrandList',
    CATEGORY_LIST: 'CategoryList',
    CATEGORY_RESULT_PAGE: 'Category',
    CHANGE_FORGOTTEN_PASSWORD_PAGE: 'ChangeForgottenPassword',
    CHANGE_PASSWORD_SUCCESS_PAGE: 'ChangePasswordSuccess',
    CUSTOM_RESULT_PAGE: 'CustomResults',
    DEFAULT: 'default',
    DETAILED_SEARCH: 'DetailedSearch',
    MEMBER_SEARCH: 'MemberSearch',
    ENDLESS_RECOMMENDATIONS_PAGE: 'EndlessRecommendation',
    ENTER_CELLPHONE_NUMBER_PAGE: 'EnterCellphoneNumber',
    ENTER_CELLPHONE_VERIFICATION_CODE_PAGE: 'EnterCellphoneVerificationCode',
    ENTER_USER_DETAILS_PAGE: 'EnterUserDetails',
    ERROR_PAGE: 'ErrorPage',
    FAVOURITE_SELLERS_PAGE: 'FavouriteSellers',
    FEEDBACK_REMOVAL_PAGE: 'FeedbackRemoval',
    FORGOT_PASSWORD_OPTIONS_PAGE: 'ForgotPasswordOptions',
    HOW_TO_BUY: 'HowToBuy',
    HOW_TO_SELL: 'HowToSell',
    IDENTIFICATION: 'Identification',
    INVALID_KEY_PAGE: 'InvalidKey',
    MY_TRADERA_PAGE: 'MyTradera',
    MY_TRADERA_PICKLIST_PRINT_VIEW: 'MyTradera_PickListPrintView',
    MY_TRADERA_ORDER_PRINT_VIEW: 'MyTradera_OrderPrintView',
    MY_SHIPPING_COMPLAINT: 'MyShippingComplaint',
    PROFILE_PAGE: 'ProfilePage',
    REPORT_ITEM_PAGE: 'ReportItem',
    RESET_EMAIL_PAGE: 'ResetEmail',
    SEARCH_RESULT_PAGE: 'SearchResults',
    SENT_EMAIL_CONFIRMATION_PAGE: 'SentEmailConfirmation',
    CAMPAIGN_PARTNER_PAGE: 'CampaignPartner',
    START_PAGE: 'StartPage',
    SYI_PAGE: 'SellItem',
    TOP_LISTS_PAGE: 'TopLists',
    TRADERA_SHOP_PAGE: 'TraderaShop',
    VALUATION: 'Valuation',
    VIEW_ITEM_PAGE: 'VIP',
    MY_MESSAGES_PAGE: 'MyMessages',
    LOGIN_PAGE: 'Login',
    MULTIFACTOR_AUTH_OPTIONS_LIST_PAGE: 'MultiFactorAuthenticationOptions',
    MULTIFACTOR_AUTH_VERIFY_CODE_PAGE: 'MultiFactorAuthenticationVerifyMFaCode',
    NEAR_YOU_PAGE: 'NearYou',
    DISPUTE_PAGE: 'Dispute',
    PAYOUT_REVIEW_PAGE: 'PayoutReview'
};
